import React, { useEffect } from "react";
import { Circle, Layer, Line, Stage, Text } from "react-konva";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";
import { SLDComponentsByType } from "./single-line-diagram-component";
import { Path } from "./SLDPath";
import { MouseButtons, SLDComponentEnum } from "./constants";
import {
	CONNECTION_POINT_RADIUS,
	MATRIX_GRID_SIZE,
	SLDComponentSizes,
	roundToGridSize
} from "../../constants/single-line-diagram";
import { SLDSimpleText } from "./SLDSimpleText";
import { getSimpleUUID } from "../../utils/get-simple-uuid";
import { SLDPlotArea } from "./SLDPlotArea";
import { logDiagramRegister } from "../../services/api/single-line-diagram";
import { withCancelBubble } from "../../utils/with-cancel-bubble";
import { is } from "date-fns/locale";

export function SingleLineDiagram({
	token
}) {
	const {
		components,
		updateComponent,
		selectedCable,
		selectedConnection,
		setSelectedConnection,
		currentPath,
		editingPath,
		setMouseCoordinates,
		paths,
		deactivateCable,
		getComponentInfoById,
		singleLineDiagramMode,
		stageInteractions,
		diagramVersionHistoric,
		contextualMenu,
		textLabels,
		dockComponentsInstance,
		voltGraphInstance,
		diagramZone,
		downloadingDiagram,
		componentSelector,
		cableInventoryState
	} = React.useContext(SingleLineDiagramContext);

	const [stageDraggable, setStageDraggable] = React.useState(true);
	/*********************************************** UTILITY FUNCTIONS ***********************************************/
	const isDrawingPath = () => (!!selectedCable) && (!!selectedConnection);
	const onPointerMove = ({ evt }) => {
		const point = getStagePointFromEvent(evt);
		setMouseCoordinates(point);
		stageInteractions.setMouseCoordinates(point);
	};

	const getStagePointFromEvent = (evt) => {
		const { offsetX: x, offsetY: y } = evt;
		return { x, y };
	};

	const getOrthoAlignedPoint = ({
		fixedPoint,
		mobilePoint,
		offset = { x: 0, y: 0 },
		forceHorizontal = false
	}) => {
		console.log("GET ORTHO ALIGNED POINT: ", { fixedPoint, mobilePoint });
		const correctedMobileX = mobilePoint.x - offset.x;
		const xIsCloser =
			Math.abs(fixedPoint.x - correctedMobileX) <
			Math.abs(fixedPoint.y - mobilePoint.y);
		const vertical = !forceHorizontal && xIsCloser;

		return {
			x: vertical ? fixedPoint.x : roundToGridSize(correctedMobileX),
			y: vertical ? roundToGridSize(mobilePoint.y) : fixedPoint.y
		};
	};

	const onClickStage = ({ evt }) => {
		const diagramPosition = {
			x: (evt?.offsetX - stageInteractions?.screenOffset?.x ) ?? 500,
			y: (evt?.offsetY - stageInteractions?.screenOffset?.y ) ?? 500
		}
		if (evt.button === MouseButtons.LEFT) {
			console.log("CLICK STAGE on diagramPosition: ", diagramPosition);
			console.log("ComponentSelector.onDrawingPath: ", componentSelector.onDrawingPath);
			if (isDrawingPath()) {
				onClickDrawingNewPath(evt);
			}
			const isEditingPath = singleLineDiagramMode.inEditCableMode() &&
				singleLineDiagramMode.hasSelectedCable();
			if (isEditingPath) {
				onClickEditingPoint(evt);
			}
		} if (evt.button === MouseButtons.RIGHT) {
			onRightClickStage({ evt });
		}
	};

	const onClickDrawingNewPath = (evt) => {
		//TODO: use clickPoint but transform copying the logic in useStageInteractions -> setMouseCoordinates
		//const clickPoint = getStagePointFromEvent(evt);
		const alignedPoint = getOrthoAlignedPoint({
			fixedPoint: currentPath.points.at(-1),
			mobilePoint: stageInteractions.pointerCoordinatesOnStage,
			offset: { x: 2 * CONNECTION_POINT_RADIUS, y: 0 },
			forceHorizontal: currentPath.points.length <= 1
		});
		currentPath.addPoint(alignedPoint);
	};

	const onClickEditingPoint = (evt) => {
		const clickPoint = getStagePointFromEvent(evt);
		const allPointsExceptLast = editingPath.points.slice(0, -1);
		const alignedPoint = getOrthoAlignedPoint({
			fixedPoint: allPointsExceptLast.at(-1),
			mobilePoint: clickPoint,
			offset: { x: 2 * CONNECTION_POINT_RADIUS, y: 0 },
			forceHorizontal: editingPath.points.length <= 1
		});
		editingPath.addPointBeforeLast(alignedPoint);
	};

	const setComponentSelector = ({type, position}) => {
		componentSelector.setType(type);
		componentSelector.setPosition(position);
	};

	const onRightClickStage = ({ evt }) => {
		try {
			if (selectedCable) {
				cableInventoryState.deleteCable(selectedCable.split("_")[1]);
			}
			dockComponentsInstance.resetDocking();
			voltGraphInstance.resetPlugsSelection();
			deactivateCable();
			currentPath.reset();
			setSelectedConnection(null);
			if (!singleLineDiagramMode.inAnyEditMode()) {
				return
			}
	
	
			const diagramPosition = {
				x: (evt?.offsetX - stageInteractions?.screenOffset?.x)/stageInteractions.scale ,
				y: (evt?.offsetY - stageInteractions?.screenOffset?.y)/stageInteractions.scale
			}

			if (isNaN(diagramPosition.x) || isNaN(diagramPosition.y)) {
				throw new Error("Invalid diagramPosition: ", diagramPosition);
			}
	
			const componentNames = {
				[SLDComponentEnum.SUBSTATION]: "subestación",
				[SLDComponentEnum.SPLITTER]: "splitter",
				[SLDComponentEnum.CELL]: "celda",
				[SLDComponentEnum.LOAD]: "carga",
				[SLDComponentEnum.NO_INVENTORY_LOAD]: "carga no inventariada"
			}
	
			const items = [
				{
					label: "Agregar texto simple",
					action: () => {
						const id = getSimpleUUID();
						console.log("Texto simple id: ", id);
						textLabels.createTextLabel({
							id,
							...diagramPosition
						});
					}
				},
				{
					label: "Agregar cable",
					action: () => {
						componentSelector.setIsAirCable(true);
						setComponentSelector({type: SLDComponentEnum.CABLE, position: diagramPosition});
					}
				}
			];
	
			Object.values(SLDComponentEnum).filter(type => type !== SLDComponentEnum.CABLE).forEach(type => {
				items.push({
					label: `Agregar ${componentNames[type]}`,
					action: () => {
						setComponentSelector({type, position: diagramPosition});
					}
				});
			});
	
	
			contextualMenu.open({
				position: { top: evt.y, left: evt.x },
				items: items
			});
		} catch (error) {
			console.error("Error onRightClickStage: ", error);
		}
	};

	/*********************************************** ZOOM AND PANNING ***********************************************/

	useEffect(() => {
		stageInteractions.calculateVisibleRange();
		window.addEventListener('resize', stageInteractions.calculateVisibleRange);

		return () => {
			window.removeEventListener('resize', stageInteractions.calculateVisibleRange);
		};
	}, []);

	const handleZoom = (e) => {
		e.evt.preventDefault();
		const zoomYStep = 120
		let deltaZoom = -(e.evt.deltaY / zoomYStep)
		deltaZoom = deltaZoom > 0 ? Math.ceil(deltaZoom) : Math.floor(deltaZoom);
		stageInteractions.zoom({ deltaZoom });
	};

	const onDragStart = (e) => {
		const { evt } = e;
		const isDragEvent = evt.buttons === 4 || (evt.buttons === 1 && evt.ctrlKey);
		if (!isDragEvent) {
			e.currentTarget.draggable(false);
			setStageDraggable(false);
			return;
		}
		stageInteractions.startStageDrag({ x: evt.clientX, y: evt.clientY });
	};

	const onDragMove = (e) => {
		const { evt } = e;
		evt.preventDefault();
	}

	const onDragEnd = (e) => {
		// Verifica si el objetivo del drag es el Stage
		if (e.target === stageInteractions.stageRef.current) {
			const { x, y } = e?.target?.attrs;
			stageInteractions.setScreenOffset({ x, y });
			setTimeout(() => {
				stageInteractions.endStageDrag();
			}, 1_000);
		}
	};

	const onMouseDown = (e) => {
		console.log("MOUSE DOWN");
		e.evt.preventDefault(); // prevents scrolling with middle mouse button
	};

	const onMouseUp = (e) => {
		console.log("MOUSE UP");
		e.currentTarget.draggable(true);
		setStageDraggable(true);
	};

	/********************************************* COMPONENT DEPLOYERS *********************************************/

	const deployPointMatrix = () => {
		if(downloadingDiagram) {
			return null;
		}
		const floorToGrid = v => Math.floor(v / MATRIX_GRID_SIZE) * MATRIX_GRID_SIZE;
		const ceilToGrid = v => Math.ceil(v / MATRIX_GRID_SIZE) * MATRIX_GRID_SIZE;

		const visibleX = [];
		const minX = floorToGrid( Math.max(
			stageInteractions.visibleRange.minX,
			diagramZone?.x
		));
		const maxX = ceilToGrid( Math.min(
			stageInteractions.visibleRange.maxX,
			diagramZone?.x + diagramZone?.width
		));
		for (let i = minX; i < maxX; i += MATRIX_GRID_SIZE) {
			visibleX.push(i);
		}

		const visibleY = [];
		const minY = floorToGrid( Math.max(
			stageInteractions.visibleRange.minY,
			diagramZone?.y
		));
		const maxY = ceilToGrid( Math.min(
			stageInteractions.visibleRange.maxY,
			diagramZone?.y + diagramZone?.height
		));
		for (let i = minY; i < maxY; i += MATRIX_GRID_SIZE) {
			visibleY.push(i);
		}

		return visibleX.map((x) => {
			return visibleY.map((y) => {
				return (
					<Circle key={`point_matrix:{${x},${y}}`} x={x} y={y} radius={2} fill={'#888'} strokeWidth={1} />
				);
			});
		});
	}

	const deployPointerLine = () => {
		if (currentPath.points.length <= 0) {
			return;
		}
		const prevPoint = currentPath.points.at(-1);
		const alignedPoint = getOrthoAlignedPoint({
			fixedPoint: prevPoint,
			mobilePoint: stageInteractions.pointerCoordinatesOnStage,
			offset: { x: 2 * CONNECTION_POINT_RADIUS, y: 0 },
			forceHorizontal: currentPath.points.length <= 1
		});

		return (
			<Line
				points={[prevPoint.x, prevPoint.y, alignedPoint.x, alignedPoint.y]}
				stroke="blue"
				opacity={0.2}
				strokeWidth={2}
			/>
		);
	};

	const deployCurrentPath = () => {
		return (
			<Path points={currentPath.points} isCurrentPath={true} />
		);
	};

	const deployPaths = () => {
		const { historicPaths } = diagramVersionHistoric;

		const { visibleRange } = stageInteractions;
		const inVisibleRange = v => {
			try {
				const { points } = v;
				const pointInRange = ({ x, y }) => (
					x > visibleRange.minX &&
					x < visibleRange.maxX &&
					y > visibleRange.minY &&
					y < visibleRange.maxY
				);

				return points.some(pointInRange);
			} catch (e) {
				return false
			}
		};
		const pathsEntries = Object.entries(singleLineDiagramMode.inHistoricMode() ? historicPaths : paths)
			.filter(([key, value]) => inVisibleRange(value) || downloadingDiagram); // culling: deploy only visible components

		const pathsComponents = pathsEntries.map(([pathId, path], index) => {
			const onError = (e) => {
				logDiagramRegister(token, {
					type: "error",
					message: `SLDPath pathComponent ${pathId} failed to load`,
					error: e?.stack
				}).then();
			};
			const fallback = () => {
				const { x, y } = path?.points?.[0] ?? { x: 100, y: 100 };
				return (
					<Text
						key={`SLDPath-path-component-failed-message:${pathId}`}
						x={x} y={y + 3*CONNECTION_POINT_RADIUS}
						text={`SLDPath pathComponent ${pathId} failed to load`}
					/>
				)
			};
			return (
				<Path
					key={"path:"+pathId}
					id={pathId}
					points={path.points}
					pathInfo={path}
					onError={onError}
					fallback={fallback}
				/>
			);
		});

		const connectionComponents = pathsEntries.map(([pathId, path], index) => {
			const onError = (e) => {
				logDiagramRegister(token, {
					type: "error",
					message: `SLDPath connectionsComponent ${pathId} failed to load`,
					error: e?.stack
				}).then();
			};
			const fallback = () => {
				const { x, y } = path?.points?.[0] ?? { x: 100, y: 100 };
				return (
					<Text
						key={`SLDPath-connections-component-failed-message:${pathId}`}
						x={x} y={y + 4*CONNECTION_POINT_RADIUS}
						text={`SLDPath connectionComponent ${pathId} failed to load`}
					/>
				)
			};
			return (
				<Path
					key={"path-connection:"+pathId}
					id={pathId}
					points={path.points}
					pathInfo={path}
					onlyConnection={true}
					onError={onError}
					fallback={fallback}
				/>
			);
		});

		return (
			<>
				{pathsComponents}
				{connectionComponents}
			</>
		);
	};

	const deployEditCablePointerLine = () => {
		const show = singleLineDiagramMode.inEditCableMode() &&
			singleLineDiagramMode.hasSelectedCable();
		if (!show) {
			return null;
		}
		const allPointsExceptLast = editingPath.points.slice(0, -1);
		if (allPointsExceptLast.length <= 0) {
			return;
		}
		const lastPoint = allPointsExceptLast.at(-1);
		const alignedPoint = getOrthoAlignedPoint({
			fixedPoint: lastPoint,
			mobilePoint: stageInteractions.pointerCoordinatesOnStage,
			offset: { x: 2 * CONNECTION_POINT_RADIUS, y: 0 },
			forceHorizontal: currentPath.points.length <= 1
		});

		return (
			<Line
				points={[lastPoint.x, lastPoint.y, alignedPoint.x, alignedPoint.y]}
				stroke="blue"
				opacity={0.2}
				strokeWidth={2}
			/>
		);
	};

	const deployEditCable = () => {
		const show = singleLineDiagramMode.inEditCableMode() &&
			singleLineDiagramMode.hasSelectedCable();

		const onClickPoint = (index) => {
			const lastIndex = editingPath.points.length - 1
			if (index !== lastIndex) {
				return;
			}

		};

		return show && (
			<Path points={editingPath.points} isCurrentPath={true} onClickPoint={onClickPoint} />
		);
	};

	const deployDiagramComponents = () => {
		const { historicComponents } = diagramVersionHistoric;
		const displayComponents = singleLineDiagramMode.inHistoricMode()
			? historicComponents
			: components;
		const componentsEntries = Object.entries(displayComponents);
		const deployComponentByEntry = ([key, value]) => {
			const { x, y, type } = value;
			const onComponentDragDone = (newState) => {
				updateComponent();
			};

			const onClickConnection = ({ type, key, compoundKey }) => {
				setSelectedConnection(compoundKey);
			};

			const getSelectedConnection = () => {
				if (selectedConnection === null) {
					return null;
				}
				const selectedKeys = selectedConnection.split(":");
				const selectedComponentKey = selectedKeys[0];
				if (selectedComponentKey === key) {
					return selectedKeys[1] + ":" + selectedKeys[2];
				}
				return null;
			};

			const Component = SLDComponentsByType[type];
			const componentInfo = getComponentInfoById(key);

			const onError = (e) => {
				logDiagramRegister(token, {
					type: "error",
					message: `SLDComponent ${type}:${key} failed to load`,
					error: e?.stack
				}).then();
			};
			const fallback = () => {
				return (
					<Text
						key={`SLDComponent-failed-message:${key}`}
						x={x}
						y={y}
						text={`SLDComponent ${type}:${key} failed to load`}
					/>
				)
			};

			return (
				<Component
					key={key}
					id={key}
					x0={x}
					y0={y}
					info={componentInfo}
					inDiagramInfo={displayComponents?.[key] ?? undefined}
					onComponentDragDone={onComponentDragDone}
					onClickConnection={onClickConnection}
					selectedConnection={getSelectedConnection()}
					isHistoric={singleLineDiagramMode.inHistoricMode()}
					fallback={fallback}
					onError={onError}
				/>
			);
		};

		const { visibleRange } = stageInteractions;
		const inVisibleRange = v => {
			const { x, y, type } = v;
			const { width = 0, height = 0 } = SLDComponentSizes?.[type] ?? {};
			return (
				x + width > visibleRange.minX &&
				x < visibleRange.maxX &&
				y + height > visibleRange.minY &&
				y < visibleRange.maxY
			);
		};

		return componentsEntries
			.filter(([key, value]) => (value && inVisibleRange(value)) || downloadingDiagram) // culling: deploy only visible components
			.map(deployComponentByEntry);
	};

	const deployTextLabels = () => {
		return Object.entries(textLabels.state).map(([key, value]) => {
			const withId = f => props => f({ id: key, ...props });

			const deployMenu = ({ evt }) => {
				if (!singleLineDiagramMode.inAnyEditMode()) {
					return;
				}
				contextualMenu.open({
					position: { top: evt.y, left: evt.x },
					items: [
						{
							label: "Eliminar texto",
							action: withId(textLabels.deleteLabel)
						}
					]
				});
			};

			const updateMethods = {
				setText: withId(textLabels.setText),
				setPosition: withId(textLabels.setPosition),
				toggleBold: withId(textLabels.toggleBold),
				setColor: withId(textLabels.setColor),
				setFontSize: withId(textLabels.setFontSize),
				setBackgroundColor: withId(textLabels.setBackgroundColor)
			};
			return <SLDSimpleText key={"text-label:"+key} {...value} {...updateMethods} deployMenu={deployMenu} />
		});
	};

	// TODO: change fix width and height to depending on parent element
	return (
		<Stage
			width={stageInteractions?.screenshotStageSize?.width ?? window.innerWidth - 280}
			height={stageInteractions?.screenshotStageSize?.height ?? window.innerHeight - 120}

			draggable={stageDraggable}
			onDragStart={onDragStart}
			onDragMove={onDragMove}
			onDragEnd={onDragEnd}
			onMouseUp={onMouseUp}
			onMouseDown={onMouseDown}

			onPointerMove={onPointerMove}
			onClick={onClickStage}
			onWheel={handleZoom}
			onContextMenu={({ evt }) => evt.preventDefault()}

			ref={stageInteractions.stageRef}
			scaleX={stageInteractions.scale}
			scaleY={stageInteractions.scale}
			{...stageInteractions.screenOffset}
			style={{ backgroundColor: 'lightblue' }}
		>
			<Layer>
				<SLDPlotArea />
				{deployPointMatrix()}
				{deployPaths()}
				{deployDiagramComponents()}
				{isDrawingPath() ? deployPointerLine() : null}
				{deployCurrentPath()}
				{deployEditCable()}
				{deployEditCablePointerLine()}
				{deployTextLabels()}
			</Layer>
		</Stage>
	);
}
/*
 * Not used because it makes diagrama slow
class StageErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			errors: []
		};
	}

	static getDerivedStateFromError(error) {
		console.log("getDerivedStateFromError")
		return {
			hasError: true
		};
	}

	componentDidCatch(error, errorInfo) {
		const errorId = error.message;
		console.log("ERROR ID: ", errorId);

		const isAlreadyLogged = this.state.errors.some(e => e.id === errorId);
		if (!isAlreadyLogged) {
			this.setState(state => ({
				errors: [...state.errors, { id: errorId, error: error, info: errorInfo }]
			}));

			if (this.props.onError) {
				this.props.onError(error, errorInfo);
			}
		}
	}

    render() {
        if (this.state.hasError) {
                if (this.props.fallback) {
                    return this.props.fallback();
                }
                return (
					<div style={{ margin: "1em", }}>
						<h2>Problema al cargar el diagrama Unilineal.</h2>
						<p>Por favor recargue, si el problema persiste contacte al equipo AIKO.</p>
					</div>
				);
        }

        return this.props.children;
    }
}*/