import { Circle, Group, RegularPolygon, Text, Shape } from "react-konva";
import React, { useContext, useEffect, useState } from "react";
import {SingleLineDiagramContext} from "./SingleLineDiagramContext";
import { TextWithPositionReference } from "./SLDPlotArea";
import { PositionReferenceTypes } from "../../constants/single-line-diagram";

import { 
    CONNECTION_POINT_HIGHLIGHTED_RADIUS, 
    CONNECTION_POINT_RADIUS,
    CONNECTION_INPUT,
    CONNECTION_OUTPUT,
    CONNECTION_CABLE_DESTINY,
    CONNECTION_CABLE_SOURCE
} from "../../constants/single-line-diagram";

export const CONNECTION_STATES = {
    "NO_ENERGY": "no-energy",
    "NO_DATA": "no-data",
    "OK": "ok",
    "YELLOW_ALARM": "yellow-alarm",
    "RED_ALARM": "red-alarm",
    "NO_SENSOR": "no-sensor",
};

export function SLDConnection({
    x,
    y,
    state = CONNECTION_STATES.NO_SENSOR,
    isSelected = false,
    isAvailable = false,
    isEndEditingPoint = false,
    type,
    hasEclamp = false,
    draggable = false,
    onClick = event => {},
    onDoubleClick = event => {},
    onMouseEnter = event => {},
    onMouseLeave = event => {},
    firstPlugEclamp = false,
    secondPlugEclamp = false,
    ...dragMethods
}) {

    const { singleLineDiagramMode, stageInteractions, donwloadingDiagram } = useContext(SingleLineDiagramContext);
    const [ isHovered, setIsHovered] = useState(false);

    const BASE_FOCUS_COLOR = "blue";

    const focusedColors = {
        [CONNECTION_OUTPUT]: "purple",
        [CONNECTION_INPUT]: "blue",
        [CONNECTION_CABLE_DESTINY]: "purple",
        [CONNECTION_CABLE_SOURCE]: "blue",
    };
    const stateColors = {
        [CONNECTION_STATES.NO_ENERGY]: "#333",
        [CONNECTION_STATES.NO_DATA]: "gray",
        [CONNECTION_STATES.OK]: "green",
        [CONNECTION_STATES.YELLOW_ALARM]: "yellow",
        [CONNECTION_STATES.RED_ALARM]: "red",
        [CONNECTION_STATES.NO_SENSOR]: "white"
    };
    const { focusedConnectionTypes, voltGraphInstance } = useContext(SingleLineDiagramContext);

    const isFocusedType = () => {
        if (singleLineDiagramMode.inAnyEditMode()){
            return focusedConnectionTypes.includes(type);
        } else {
            return voltGraphInstance.isFirstPlugSelected() && hasEclamp;
        }
    };

    const isHighlightedEditMode = () => {
        return (isFocusedType() && isAvailable) || isEndEditingPoint;
    };

    const onlyViewColor = isFocusedType() ? BASE_FOCUS_COLOR : stateColors[state];
    const editColor = isHighlightedEditMode() ? focusedColors[type] : "white";
    const defaultColor = "white";
    const getFillColor = () => {
        if (singleLineDiagramMode.inAnyEditMode()){
            return editColor;
        } if (singleLineDiagramMode.inOnlyViewMode()){
            return onlyViewColor;
        } 
        return defaultColor;
    }
    const strokeColor = "black";

    const getPolygonColor = () => {
        if (singleLineDiagramMode.inAnyEditMode()){
            return "black";
        } if (singleLineDiagramMode.inOnlyViewMode()){
            if (CONNECTION_STATES.NO_DATA === state){
                return "white";
            } else {
                return "black";
            }
        }
        return "black";
    }

    const radius = [CONNECTION_STATES.OK, CONNECTION_STATES.NO_ENERGY, CONNECTION_STATES.NO_SENSOR].includes(state)
        ? CONNECTION_POINT_RADIUS
        : CONNECTION_POINT_HIGHLIGHTED_RADIUS;

    const getHoverText = () => {
        let text = "";
        let textSize = 0;
        if (CONNECTION_STATES.NO_DATA === state){
            text = "Sin datos";
            textSize = text.length;
            return {text, textSize};
        } else if (CONNECTION_STATES.YELLOW_ALARM === state){
            text = "Alarma amarilla";
            textSize = text.length;
            return {text, textSize};
        } else if (CONNECTION_STATES.RED_ALARM === state){
            text = "Alarma roja";
            textSize = text.length;
            return {text, textSize};
        }
    }
    const highLevelOfDetail = stageInteractions.scale > 0.6 || donwloadingDiagram;
    
    useEffect(() => {
        console.log("ConnectionType: ", type);
        console.log("left: ", firstPlugEclamp);
        console.log("right: ", secondPlugEclamp);
        console.log("hasEclamp: ", hasEclamp);
    }, []);
    return (
        <>
            <Group
                onClick={onClick}
                onDblClick={onDoubleClick}
                onMouseEnter={(props) => {
                    setIsHovered(true);
                    onMouseEnter(props);
                }}
                onMouseLeave={(props) => {
                    setIsHovered(false);
                    onMouseLeave(props);
                }}
                x={x} y={y}
                draggable={draggable}
                {...dragMethods}
            >
                {firstPlugEclamp ? (
                    <>
                        <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                // semicirculo izquierdo
                                context.arc(0, 0, CONNECTION_POINT_RADIUS, Math.PI / 2, -Math.PI / 2, false);
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            fill={getFillColor()}
                            stroke={strokeColor}
                            strokeWidth={isSelected ? 3 : 1}
                        />
                        <Shape
                            sceneFunc={(context, shape) => {
                                context.beginPath();
                                // semicirculo derecho
                                context.arc(0, 0, CONNECTION_POINT_RADIUS, Math.PI / 2, -Math.PI / 2, true);
                                context.closePath();
                                context.fillStrokeShape(shape);
                            }}
                            fill="white" // color del segundo semicírculo
                            stroke={strokeColor}
                            strokeWidth={isSelected ? 3 : 1}
                        />
                    </>
                ) : (
                    secondPlugEclamp ? (
                        <>
                            <Shape
                                sceneFunc={(context, shape) => {
                                    context.beginPath();
                                    // semicirculo izquierdo
                                    context.arc(0, 0, CONNECTION_POINT_RADIUS, Math.PI / 2, -Math.PI / 2, false);
                                    context.closePath();
                                    context.fillStrokeShape(shape);
                                }}
                                fill="white"
                                stroke={strokeColor}
                                strokeWidth={isSelected ? 3 : 1}
                            />
                            <Shape
                                sceneFunc={(context, shape) => {
                                    context.beginPath();
                                    // semicirculo derecho
                                    context.arc(0, 0, CONNECTION_POINT_RADIUS, Math.PI / 2, -Math.PI / 2, true);
                                    context.closePath();
                                    context.fillStrokeShape(shape);
                                }}
                                fill={getFillColor()} // color del segundo semicírculo
                                stroke={strokeColor}
                                strokeWidth={isSelected ? 3 : 1}
                            />
                        </>
                    ) : (
                        <>
                            <Circle
                                radius={CONNECTION_POINT_RADIUS}
                                fill={getFillColor()}
                                strokeWidth={isSelected ? 3 : 1}
                                opacity={isSelected || singleLineDiagramMode.inOnlyViewMode() ? 1 : 0.8}
                                stroke={strokeColor}
                            />
                        </>
                    ))}
                {highLevelOfDetail && (
                    <RegularPolygon
                        sides={3}
                        radius={CONNECTION_POINT_RADIUS}
                        stroke={getPolygonColor()}
                        strokeWidth={1}
                        rotation={0}
                    />
                )}
            </Group>
            {isHovered && hasEclamp && (
                <TextWithPositionReference
                    x={x - CONNECTION_POINT_RADIUS - 5}
                    y={y + CONNECTION_POINT_RADIUS + 5}
                    text={getHoverText()?.text}
                    fill={"#2c3a55ff"}
                    fontSize={15}
                    positionReference={PositionReferenceTypes.TOP_RIGHT}
                />
            )}
        </>
    );
}